@import "../../scss/_bootstrap-configuration";

$text-color: $gray-700;
$transition-speed: 0.5s;

.Footer {
  // Make text color a bit lighter than default color so that contrast with active link is stronger

  color: $text-color;
  padding-top: 2em;

  .FooterItem {
    padding-bottom: 0.5em;
    cursor: pointer;
    //appliquer le meme style du footerItem sur les a
    &, a {
      color: $light-background;
      text-decoration: none;
      @extend .FooterItemHoverTransition;
    }
  }
}

.FooterHeaderItem {
  padding-bottom: 1em;
  color: $light-background;
  font-weight: bold;
  font-size: 1.3em;
}

.SocialIcon {
  margin-right: 0.5em;
  width: 2em !important;
  height: 2em!important;
  @extend .SocialIconHoverTransition;
}

// Transition hover et not hover sur éléments
.SocialIconHoverTransition {
  &:hover {
    margin-top: -1em;
    transition: $transition-speed;
  }

  &:not(:hover) {
    margin-top: 0;
    transition: $transition-speed;
  }
}

.FooterItemHoverTransition {
  &:hover {
    padding-left: 0.7em;
    color: darken($light-background, 50%);
    transition: $transition-speed;
  }

  &:not(:hover) {
    padding-left: 0;
    transition: $transition-speed;
  }
}

.companySignature {
  padding-top: 1em;
  padding-bottom: 0.5em;
  color: $text-color;
  text-align: center;
  align-content: center;
}

// Changer la taille de la police selon la taille de l'écran

@include media-breakpoint-down(sm) {
  .FooterHeaderItem {
    font-size: 1.1em;
  }
  .FooterItemGroup {
    font-size: 0.8em;
  }

}

@include media-breakpoint-down(md) {
  .FooterItemGroup {
    font-size: 0.8em;
  }
  .FooterHeaderItem {
    font-size: 1.2em;
  }
}

// Import every single file imported by /node_modules/bootstrap/scss/bootstrap.scss in its "Configuration" section
// Also import our own scss files required to override Bootstrap styles before Bootstrap is imported.
@import "./_colors";

// Include functions first (so we can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// Declarations used by "@import bootstrap" to generate themes
$primary: $maw-blue;
$secondary: $maw-green;
$tertiary:$label-grey;
$success: $success-green;
$warning: $warning-orange;
$danger: $error-red;

$body-color: $maw-blue;
$enable-rounded: false;
$border-color: $maw-blue;
$input-border-color: $maw-blue;
$input-btn-focus-width: 2px;
$navbar-brand-padding-y: 1rem;

// Import the rest of the files from the "configuration" section in ~bootstrap/scss/bootstrap ("functions" has been imported above)
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_mixins";
@import "~bootstrap/scss/_utilities";
